import ProductCard from './ProductCard'
import GallerySlider from './GallerySlider'
import './skills.css'

const Skills = () => {
    return (
        <section className="skills section" id="skills">
            <h2 className="section__title">Portfolio</h2>
            <span className="section__subtitle">Check My Recent Work </span>

            <div className="skills__container container grid">
                <div className="portfolio-item">
                    <ProductCard />
                </div>
                <div className="portfolio-item">
                    <GallerySlider />
                </div>
            </div>
        </section>
    )
}

export default Skills