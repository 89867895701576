import React, { useState } from 'react';
import './ProductCard.css'; // We'll create a new CSS file

const ProductCard = () => {
    const [activeTab, setActiveTab] = useState(0);

    const tabs = [
        { title: 'Games', icon: 'bx bxs-joystick', content: 'I have created Some Games. Please Check and give your feedback.' },
        { title: 'Tools', icon: 'bx bx-cog', content: 'A Simple Website with Some Tools.' },
    ];

    const gameLinks = [
        { 
            title: 'Chess', 
            url: 'https://iamumar.site/games/chess1/',
            icon: 'bx bxs-chess'
        },
        { 
            title: 'Stick Hero', 
            url: 'https://iamumar.site/games/stick-hero/',
            icon: 'bx bx-run'
        },
        { 
            title: 'Tic Tac', 
            url: 'https://iamumar.site/games/tik-tac.html',
            icon: 'bx bx-grid-alt'
        },
        { 
            title: 'Memory Card', 
            url: 'https://iamumar.site/games/memory.html',
            icon: 'bx bx-memory-card'
        },
    ];

    const openLink = (url) => {
        window.open(url, '_blank');
    };

    return (
        <div className="product-card">
            <div className="product-card__tabs">
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        className={`product-card__tab ${activeTab === index ? 'product-card__tab--active' : ''}`}
                        onClick={() => setActiveTab(index)}
                        type="button"
                    >
                        <i className={tab.icon}></i>
                        <span>{tab.title}</span>
                    </button>
                ))}
            </div>
            <div className="product-card__content">
                <p className="product-card__description">{tabs[activeTab].content}</p>

                {activeTab === 1 && (
                    <button 
                        onClick={() => openLink('https://iamumar.site/')} 
                        className="product-card__button product-card__button--primary"
                        type="button"
                    >
                        Open  
                        <i className="bx bx-link-external"></i>
                    </button>
                )}

                {activeTab === 0 && (
                    <div className="product-card__grid">
                        {gameLinks.map((game, index) => (
                            <button 
                                key={index} 
                                onClick={() => openLink(game.url)} 
                                className="product-card__button product-card__button--secondary"
                                type="button"
                            >
                                <i className={game.icon}></i>
                                <span>{game.title}</span>
                            </button>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductCard;
