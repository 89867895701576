import React from 'react';
import { Helmet } from 'react-helmet-async';

const NavigationSchema = () => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "M UMAR Portfolio",
            "url": "https://itsumar.xyz/",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://itsumar.xyz/?s={search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }
        `}
      </script>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Home",
                "item": "https://itsumar.xyz/"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "About",
                "item": "https://itsumar.xyz/#about"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Skills",
                "item": "https://itsumar.xyz/#skills"
              },
              {
                "@type": "ListItem",
                "position": 4,
                "name": "Services",
                "item": "https://itsumar.xyz/#services"
              },
              {
                "@type": "ListItem",
                "position": 5,
                "name": "Qualification",
                "item": "https://itsumar.xyz/#qualification"
              },
              {
                "@type": "ListItem",
                "position": 6,
                "name": "Portfolio",
                "item": "https://itsumar.xyz/#portfolio"
              },
              {
                "@type": "ListItem",
                "position": 7,
                "name": "Contact",
                "item": "https://itsumar.xyz/#contact"
              }
            ]
          }
        `}
      </script>
    </Helmet>
  );
};

export default NavigationSchema; 