import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./GallerySlider.css";

const sliderStyles = {
  width: '90%',
  maxWidth: '400px',
  margin: '2rem auto',
  borderRadius: '12px',
  color: '#FFFFFF',
  padding: '0',
  boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
  minHeight: '300px',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
};

const imageStyles = {
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  borderRadius: '12px',
};

// Custom arrow components
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ 
        ...style, 
        display: "block", 
        background: "white",
        color: "black",
      }}
      onClick={onClick}
    >
      <span style={{ fontSize: "24px" }}>&#8250;</span>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ 
        ...style, 
        background: "white",
        color: "black",
        padding: 'auto'
      }}
      onClick={onClick}
    >
      <span style={{ left: "-10px",  fontSize: "24px" }}>&#8249;</span>
    </div>
  );
}

function GallerySlider() {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    arrows: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="gallery-slider-container">
      <div style={sliderStyles} className="slider-container">
        <Slider {...settings}>
          <div>
            <img src="https://iili.io/JFZCbyu.jpg" alt="Food" style={imageStyles} />
          </div>
          <div>
            <img src="https://iili.io/dXp7IWX.png" alt="Bicycle" style={imageStyles} />
          </div>
          <div>
            <img src="https://iili.io/H1oTkDN.jpg" alt="Profile" style={imageStyles} />
          </div>
          <div>
            <img src="https://iili.io/dXpdlGn.png" alt="Sheo" style={imageStyles} />
          </div>
          <div>
            <img src="https://iili.io/H1oTUiX.jpg" alt="Watch" style={imageStyles} />
          </div>
          <div>
            <img src="https://iili.io/H1ouRzN.jpg" alt="Watch2" style={imageStyles} />
          </div>
          <div>
            <img src="https://iili.io/H1oT4ls.jpg" alt="Sheo2" style={imageStyles} />
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default GallerySlider;
