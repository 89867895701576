import React from 'react';
import { Helmet } from 'react-helmet-async';

const SEO = () => {
  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>M UMAR | Web Developer & UI/UX Designer | itsumar.xyz</title>
      <meta name="title" content="M UMAR | Web Developer & UI/UX Designer | Portfolio" />
      <meta name="description" content="Professional portfolio of M UMAR, a skilled web developer and UI/UX designer. View my work or contact me directly for your next web project. Find me at itsumar.xyz" />
      <meta name="keywords" content="M UMAR, UMAR, web developer, frontend developer, UI designer, UX designer, portfolio, React developer, itsumar.xyz, contact M UMAR, about M UMAR" />
      <meta name="author" content="M UMAR" />
      
      {/* Canonical link */}
      <link rel="canonical" href="https://itsumar.xyz" />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://itsumar.xyz/" />
      <meta property="og:title" content="M UMAR | Web Developer & UI/UX Designer" />
      <meta property="og:description" content="Professional portfolio of M UMAR, a skilled web developer and UI/UX designer. View my About page or Contact me directly through itsumar.xyz" />
      <meta property="og:image" content="https://itsumar.xyz/og-image.jpg" />
      
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://itsumar.xyz/" />
      <meta property="twitter:title" content="M UMAR | Web Developer & UI/UX Designer" />
      <meta property="twitter:description" content="Professional portfolio of M UMAR, a skilled web developer and UI/UX designer. Learn more About me or Contact me at itsumar.xyz" />
      <meta property="twitter:image" content="https://itsumar.xyz/twitter-image.jpg" />
      
      {/* Additional SEO optimization */}
      <meta name="robots" content="index, follow" />
      <meta name="language" content="English" />
      <meta name="revisit-after" content="7 days" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      
      {/* Structured data for better Google Search results */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org/",
            "@type": "Person",
            "name": "M UMAR",
            "url": "https://itsumar.xyz",
            "image": "https://itsumar.xyz/profile-image.jpg",
            "sameAs": [
              "https://www.linkedin.com/in/mumar",
              "https://twitter.com/mumar",
              "https://github.com/mumar"
            ],
            "jobTitle": "Web Developer & UI/UX Designer",
            "worksFor": {
              "@type": "Organization",
              "name": "Freelance"
            },
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://itsumar.xyz/"
            },
            "description": "M UMAR is a professional web developer and UI/UX designer with expertise in creating modern, responsive websites and applications."
          }
        `}
      </script>
      
      {/* Additional structured data for About page */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org/",
            "@type": "AboutPage",
            "url": "https://itsumar.xyz/#about",
            "name": "About M UMAR - Web Developer & Designer",
            "description": "Learn more about M UMAR's skills, experience, and background in web development and UI/UX design.",
            "mainEntity": {
              "@type": "Person",
              "name": "M UMAR",
              "url": "https://itsumar.xyz"
            }
          }
        `}
      </script>
      
      {/* Additional structured data for Contact page */}
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org/",
            "@type": "ContactPage",
            "url": "https://itsumar.xyz/#contact",
            "name": "Contact M UMAR - Web Developer & Designer",
            "description": "Get in touch with M UMAR for web development and design services.",
            "mainEntity": {
              "@type": "Person",
              "name": "M UMAR",
              "email": "contact@itsumar.xyz"
            }
          }
        `}
      </script>
    </Helmet>
  );
};

export default SEO; 