import React from 'react';
import { Helmet } from 'react-helmet-async';

const EventSEO = () => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Event",
            "name": "Web Development Workshop with M UMAR",
            "startDate": "2023-08-15T09:00",
            "endDate": "2023-08-15T17:00",
            "eventStatus": "https://schema.org/EventScheduled",
            "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
            "location": {
              "@type": "VirtualLocation",
              "url": "https://itsumar.xyz/workshop"
            },
            "image": [
              "https://itsumar.xyz/workshop-image.jpg"
            ],
            "description": "Join M UMAR for a comprehensive workshop on modern web development techniques and best practices. Learn how to build responsive, user-friendly websites using React and other cutting-edge technologies.",
            "offers": {
              "@type": "Offer",
              "url": "https://itsumar.xyz/#contact",
              "price": "99.99",
              "priceCurrency": "USD",
              "availability": "https://schema.org/InStock",
              "validFrom": "2023-07-21T00:00"
            },
            "performer": {
              "@type": "Person",
              "name": "M UMAR",
              "url": "https://itsumar.xyz"
            },
            "organizer": {
              "@type": "Person",
              "name": "M UMAR",
              "url": "https://itsumar.xyz"
            }
          }
        `}
      </script>
    </Helmet>
  );
};

export default EventSEO; 