import React from 'react';
import { Helmet } from 'react-helmet-async';

const FAQSEO = () => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "What services does M UMAR provide?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "M UMAR offers professional web development and UI/UX design services, specializing in creating responsive, user-friendly websites and applications using modern technologies like React."
                }
              },
              {
                "@type": "Question",
                "name": "How can I contact M UMAR for a project?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "You can easily contact M UMAR through the contact form on the website at https://itsumar.xyz/#contact. Alternatively, you can reach out via email or social media platforms linked on the site."
                }
              },
              {
                "@type": "Question",
                "name": "What is M UMAR's experience in web development?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "M UMAR has extensive experience in web development, with expertise in frontend technologies including React, JavaScript, HTML5, and CSS3. For more details about skills and experience, visit the About section at https://itsumar.xyz/#about."
                }
              },
              {
                "@type": "Question",
                "name": "Does M UMAR provide remote services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, M UMAR provides remote web development and design services to clients worldwide. Distance is not a barrier to delivering high-quality, professional results for your project."
                }
              },
              {
                "@type": "Question",
                "name": "What types of projects has M UMAR worked on?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "M UMAR has worked on various projects including e-commerce websites, portfolio sites, business applications, and interactive web experiences. You can view samples of previous work in the Portfolio section at https://itsumar.xyz/#portfolio."
                }
              }
            ]
          }
        `}
      </script>
    </Helmet>
  );
};

export default FAQSEO; 