import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

const SectionSEO = () => {
  const [currentSection, setCurrentSection] = useState('');

  useEffect(() => {
    // Check the URL hash to determine the active section
    const checkSection = () => {
      const hash = window.location.hash;
      if (hash) {
        // Remove the # character
        setCurrentSection(hash.substring(1));
      } else {
        setCurrentSection('home');
      }
    };

    // Call on mount and when hash changes
    checkSection();
    window.addEventListener('hashchange', checkSection);

    return () => {
      window.removeEventListener('hashchange', checkSection);
    };
  }, []);

  // Only render additional metadata if we're on the about or contact section
  if (currentSection === 'about') {
    return (
      <Helmet>
        <title>About M UMAR | Web Developer & UI/UX Designer | itsumar.xyz</title>
        <meta name="description" content="Learn more about M UMAR's professional background, skills, and experience in web development and UI/UX design." />
        <link rel="canonical" href="https://itsumar.xyz/#about" />
      </Helmet>
    );
  }

  if (currentSection === 'contact') {
    return (
      <Helmet>
        <title>Contact M UMAR | Web Developer & UI/UX Designer | itsumar.xyz</title>
        <meta name="description" content="Get in touch with M UMAR for your web development and design needs. Quick response guaranteed." />
        <link rel="canonical" href="https://itsumar.xyz/#contact" />
      </Helmet>
    );
  }

  // Return null if not on a special section
  return null;
};

export default SectionSEO; 