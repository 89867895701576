import React from 'react';
import { Helmet } from 'react-helmet-async';

const ImageSEO = () => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org/",
            "@type": "ImageObject",
            "contentUrl": "https://itsumar.xyz/profile-image.jpg",
            "license": "https://itsumar.xyz/license",
            "acquireLicensePage": "https://itsumar.xyz/contact",
            "creditText": "M UMAR - Official Portfolio",
            "creator": {
              "@type": "Person",
              "name": "M UMAR"
            },
            "copyrightNotice": "© M UMAR"
          }
        `}
      </script>
    </Helmet>
  );
};

export default ImageSEO; 