import React from 'react';
import './App.css';
import './components/noselect.css';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Home from './components/home/Home';
import Qualification from './components/qualification/Qualification';
import ScrollUp from './components/scrollup/ScrollUp';
import Services from './components/services/Services';
import Skills from './components/skills/Skills';
import Ports from './components/GallerySlider/Ports';
import CursorParticles from './components/CursorParticles';
import { ThemeProvider } from './theme/ThemeContext';
import SEO from './components/SEO/SEO';
import SectionSEO from './components/SEO/SectionSEO';
import NavigationSchema from './components/SEO/NavigationSchema';
import ImageSEO from './components/SEO/ImageSEO';
import LocalBusinessSEO from './components/SEO/LocalBusinessSEO';
import FAQSEO from './components/SEO/FAQSEO';
import EventSEO from './components/SEO/EventSEO';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <SEO />
        <SectionSEO />
        <NavigationSchema />
        <ImageSEO />
        <LocalBusinessSEO />
        <FAQSEO />
        <EventSEO />
        <div className="app-wrapper">
          <CursorParticles 
            quantity={50}
            color="#333333"
            darkColor="#aaaaaa"
            staticity={45}
            ease={60}
            size={0.6}
          />
          <Header />
          <main className="main">
            <Home />
            <About />
            <Skills />
            <Services />
            <Qualification />
            <Ports />
            <Contact />
          </main>
          <Footer />
          <ScrollUp />
        </div>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
