import React from 'react';
import { Helmet } from 'react-helmet-async';

const LocalBusinessSEO = () => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": "M UMAR Web Development Services",
            "image": "https://itsumar.xyz/profile-image.jpg",
            "url": "https://itsumar.xyz",
            "telephone": "+1234567890",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Your Street Address",
              "addressLocality": "Your City",
              "addressRegion": "Your Region",
              "postalCode": "Your Postal Code",
              "addressCountry": "Your Country"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 40.7128,
              "longitude": -74.0060
            },
            "openingHoursSpecification": {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "09:00",
              "closes": "17:00"
            },
            "sameAs": [
              "https://www.linkedin.com/in/mumar",
              "https://twitter.com/mumar",
              "https://github.com/mumar"
            ],
            "priceRange": "$$"
          }
        `}
      </script>
    </Helmet>
  );
};

export default LocalBusinessSEO; 