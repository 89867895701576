import React, { useContext } from 'react';
import { ThemeContext } from '../../theme/ThemeContext';
import './theme.css';

const ThemeToggle = () => {
  const { isDark, toggleTheme } = useContext(ThemeContext);

  return (
    <div className="theme-toggle" onClick={toggleTheme}>
      <i className={`uil ${isDark ? 'uil-sun' : 'uil-moon'}`}></i>
    </div>
  );
};

export default ThemeToggle; 